import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"shadow1_important rounded white",attrs:{"fluid":""}},[_c(VForm,{staticClass:"custom-form",on:{"submit":function($event){$event.preventDefault();return _vm.getcustomerDue.apply(null, arguments)}}},[_c(VRow,{attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"4"}},[_c(VRow,{staticStyle:{"margin-top":"3px","color":"#000","align-items":"center"},attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"3"}},[_vm._v("Customer")]),_c(VCol,{attrs:{"cols":"9"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.customers,"loading":_vm.$store.getters['customer/loadingCustomers'],"item-text":"display_text","item-value":"id"},on:{"focus":function($event){return _vm.$store.dispatch('customer/getCustomers')}},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1)],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{staticClass:"text_bg_fave",attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Search")])],1)],1),_c(VDivider)],1),(_vm.$store.getters['customer/customerDue'].length != 0)?_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{on:{"click":function($event){$event.preventDefault();return _vm.print.apply(null, arguments)}}},[_vm._v("Print")])],1),_c(VCol,{attrs:{"cols":"12","id":"invoiceContent"}},[_c(VSimpleTable,{staticClass:"custom-table"},[_c(VDataTable,{staticClass:"custom-data-table white shadow1 rounded py-4 px-3",attrs:{"headers":_vm.customerHeaders,"items":_vm.$store.getters['customer/customerDue'],"loading":_vm.$store.getters['product/loadingProducts'],"search":_vm.searchCustomer,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.showDetails}})],1)],1)],1):_c('div',{staticClass:"no_result"},[_c('div',{staticClass:"img"})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }